<template>
  <div>
    <transition-group
      class="voting-cards"
      name="list"
      tag="p"
    >
      <div
        v-for="point in restPoints"
        :key="point.id"
        :class="[
          `card-${point.id} card ${!clicked ? 'hover' : '' }`,
          {'clicked' : (clicked === point.id) && pointCards.length }
        ]"
        @click="clickCard(point.value)"
      >
        <div class="point-first">
          {{ point.value }}
        </div>
        <div class="point-middle">
          {{ point.value }}
        </div>
        <div class="point-third">
          {{ point.value }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Task } from '@timeragent/core';

export default {
  name: 'VotingCards',
  props: {
    pokerSession: {
      type: Object,
      require: false,
      default: () => null,
    },
  },
  data() {
    return {
      clicked: null,
      points: [
        { id: '1', value: 0, clicked: false, count: true },
        { id: '2', value: 1, clicked: false, count: true },
        { id: '3', value: 2, clicked: false, count: true },
        { id: '4', value: 3, clicked: false, count: true },
        { id: '5', value: 5, clicked: false, count: true },
        { id: '6', value: 8, clicked: false, count: true },
        { id: '7', value: 13, clicked: false, count: true },
        { id: '8', value: '?', clicked: false, count: false },
        { id: '9', value: 'Pass', clicked: false, count: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    isUserVoted() {
      return (this.pokerSession.exists() && this.pokerSession.activeTask && this.pokerSession.activeTask.votes)
        ? this.pokerSession.activeTask.votes.some(el => el.userUuid === this.user.uuid) : false;
    },
    votedPoint() {
      return this.isUserVoted ? this.pokerSession.activeTask.votes.find(el => el.userUuid === this.user.uuid) : {};
    },
    restPoints() {
      return this.isUserVoted ? this.points.filter(el => el.value !== this.votedPoint.score) : this.points;
    },
    joinedUser() {
      return this.pokerSession.users.find(el => el.uuid === this.user.uuid).options.pokerSessionStatus === 'JOINED';
    },
  },
  methods: {
    clickCard(value) {
      const card = {
        userUuid: this.user.uuid,
        score: value,
      };

      if (this.joinedUser && !this.pokerSession.activeTask.votes && !this.isUserVoted) {
        this.pokerSession.activeTask.votes = [card];
        this.updateTaskVotes();
        this.$emit('update-task-score');
      }
      if (this.joinedUser && this.pokerSession.activeTask.votes && !this.isUserVoted) {
        this.pokerSession.activeTask.votes.push(card);
        this.updateTaskVotes();
        this.$emit('update-task-score');
      }
    },
    updateTaskVotes() {
      const task = new Task(this.pokerSession.activeTask);

      task.update();
    },
  },
};
</script>

<style lang="stylus"
       scoped>

@media all and (min-width: 1456px) {
  .voting-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .card {
    display: flex;
    height: 140px;
    width: 100px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .5);
    border-radius: 4%;
    cursor: pointer;
    color: #ffffff;
    flex-direction: column;
    /*margin-right: -30px;*/
  }
}

@media all and (max-width: 1455px) {
  .voting-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -20px;
  }

  .card {
    display: flex;
    height: 140px;
    width: 100px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .5);
    border-radius: 4%;
    cursor: pointer;
    color: #ffffff;
    flex-direction: column;
    margin-right: -20px;
  }
}

@media all and (max-width: 1260px) {
  .voting-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -30px;
  }

  .card {
    display: flex;
    height: 140px;
    width: 100px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .5);
    border-radius: 4%;
    cursor: pointer;
    color: #ffffff;
    flex-direction: column;
    margin-right: -30px;
  }
}

@media all and (max-width: 1090px) {
  .voting-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: -35px;
  }

  .card {
    display: flex;
    height: 140px;
    width: 100px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, .5);
    border-radius: 4%;
    cursor: pointer;
    color: #ffffff;
    flex-direction: column;
    margin-right: -35px;
  }
}

.point-first {
  padding: 10px;
  font-size: 10px;
  flex-grow: 1;
}

.point-middle {
  padding: 10px;
  flex-grow: 1;
  font-size: 37px;
  display: flex;
  justify-content: center;
}

.point-third {
  padding: 10px;
  font-size: 10px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  transform: rotate(180deg);
}

.card-1 {
  /*left: 1vw;*/
  z-index: 1;
  background: #409eff;
}

.card-2 {
  /*left: 7.8vw;*/
  z-index: 2;
  background: #409eff;
}

.card-3 {
  /*left: 14.6vw;*/
  z-index: 3;
  background: #409eff;
}

.card-4 {
  /*left: 21.4vw;*/
  z-index: 4;
  background: #409eff;
}

.card-5 {
  /*left: 28.2vw;*/
  z-index: 5;
  background: #68c23a;
}

.card-6 {
  /*left: 35vw;*/
  z-index: 6;
  background: #68c23a;
}

.card-7 {
  /*left: 41.8vw;*/
  z-index: 7;
  background: #68c23a;
}

.card-8 {
  /*left: 48.6vw;*/
  z-index: 8;
  background: #fbc530;
}

.card-9 {
  /*left: 55.4vw;*/
  z-index: 8;
  background: #f7f7f7;
  color: #676f7a;
}

.clicked {
  z-index: 10;
  /*transform: translate(10px, -45px) rotateZ(5deg);*/
}

.hover {
  &:hover {
    transform: translate(10px, -45px) rotateZ(5deg);
    z-index: 12;
    /*transform: scale(1.15);*/
    /*transition: .1s;*/
  }
}

.list-enter-active, .list-leave-active {
  transition: all 0.5s;
}

.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(-30px);
}
</style>
